import React, { useEffect, useState } from "react";
import "./plan.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Plan() {
  const [clickBurger, setClickBurger] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <div
        className={`site-head container-fluid head- ${clickBurger ? "transparent-bg color-me" : ""
          }`}
      >
        <div className="site-head--logo">
          <a className="logo" href="/">
            <img src="images/GanlazTwo.png" alt="img" />
          </a>
        </div>
        <div className="site-head--nav d-none d-lg-block">
          <nav className="menu-main-container">
            <ul id="menu-main" className="nav primary">
              <li
                id="menu-item-28"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28"
              >
                <a href="/you" className="menu__link">
                  You
                </a>
              </li>
              <li
                id="menu-item-27"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27"
              >
                <a href="/us" className="menu__link">
                  Me
                </a>
              </li>
              <li
                id="menu-item-26"
                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-18 current_page_item menu__item menu-item-26"
              >
                <a href="/plan" className="menu__link">
                  Plan
                </a>
              </li>
              <li
                id="menu-item-25"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25"
              >
                <a href="/invest" className="menu__link">
                  Invest
                </a>
              </li>
              <li
                id="menu-item-666"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666"
              >
                <a href="/pricing" className="menu__link">
                  Pricing
                </a>
              </li>
              <li
                id="menu-item-29"
                className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29"
              >
                <a href="/contact" className="menu__link">
                  Discover
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-head__mobile-menu d-block d-lg-none">
          <div
            onClick={() => setClickBurger(!clickBurger)}
            className="hamburger"
          >
            <span className={`hb-top ${clickBurger ? "shorter" : ""}`}></span>
            <span className="hb-middle"></span>
            <span className={`hb-bottom ${clickBurger ? "longer" : ""}`}></span>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${clickBurger ? "active" : ""}`}>
        <div className="mobile-menu__container container-fluid">
          <ul id="menu-main-1" className="mobile-menu__primary">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28">
              <a href="/you" className="menu__link">
                You
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27">
              <a href="/us" className="menu__link">
                Me
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-18 current_page_item menu__item menu-item-26">
              <a href="/plan" className="menu__link">
                Plan
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25">
              <a href="/invest" className="menu__link">
                Invest
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666">
              <a href="/pricing" className="menu__link">
                Pricing
              </a>
            </li>
            <li className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29">
              <a href="/contact" className="menu__link">
                Discover
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Plan main */}

      <main>
        <nav className="plottspy navbar d-none d-md-block">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a className="nav-link" href="#section1"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section2"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section3"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section4"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section5"></a>
            </li>
          </ul>
        </nav>

        <div className="slides__colour">
          <div id="section1" className="section">
            <section
              id="section"
              className="hero-image panel"
              data-color="orange"
            >
              <div
                className="image-wrap--img"
                // background-image: url('images/vobis_pic3-1.jpg')
                style={{
                  backgroundImage: "url('images/plan_new.jpg')",
                }}
              ></div>

              <div className="title-home">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <span>Plan</span>
                  <p className="pp_sub">Meticulously</p>
                </h1>
                <a href="#section2" className="smooth">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section2" className="section">
            <section
              className="panel centre-align-slide plan_sec2_color"
              data-color="blue"
            //    background-image: url('images/bg-question-centre.png');
            //   background-position: center;
            // style={{
            //   backgroundImage: "url('images/bg-question-centre.png')",
            //   backgroundPosition: "center",
            // }}
            >
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                Importance of financial planning for you and your family{" "}
              </p>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section3" className="section">
            <section
              className="panel left-align-slide plan_sec3_color"
              data-color="orange"
            //    background-image: url('images/bg-leaf-right.png')
            // style={{
            //   backgroundImage: "url('images/bg-leaf-right.png')",
            // }}
            >
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                Financial planning is crucial for achieving goals like
                homeownership, education, and retirement. It establishes clear
                objectives, ensuring efficient resource allocation. Without it,
                individuals risk financial insecurity and struggle with
                unforeseen expenses.
              </p>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section4" className="section">
            <section
              className="panel right-align-slide plan_sec4_color"
              data-color="green"
            //   background-image: url('images/bg-leaf-left.png')
            // style={{
            //   backgroundImage: "url('images/bg-leaf-left.png')",
            // }}
            >
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                {/* We look at the risks, complexities and opportunities of the
                markets�&nbsp;and piece together your life, your wealth and your
                values into one cohesive plan that is right for today, and can
                adapt to any future changes. */}
                Financial planning encourages disciplined and responsible money
                management through budgeting. This structured approach
                identifies unnecessary expenditures, enabling adjustments for
                wise resource investment, essential for building and maintaining
                wealth while instilling confidence in financial management.
              </p>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section5" className="section">
            <section
              className="panel left-align-slide plan_sec5_color"
              data-color="grey"
            //   background-image: url('images/bg-left-centre_right.png')
            // style={{
            //   backgroundImage: "url('images/bg-left-centre_right.png')",
            // }}
            >
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                Financial planning, including insurance, emergency funds, and
                estate planning, acts as a crucial risk management tool,
                ensuring stability in the face of uncertainties. It is
                indispensable for achieving success, maintaining financial
                health, and navigating life's challenges with resilience.
              </p>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>
        </div>
      </main>

      {/* footer */}
      <footer id="footer" className="snap">
        <img src="images/GanlazIconTwo-pre.svg" alt="ganlaz logo" />
        <h2>Embark on a</h2>
        <h2>new financial journey </h2>
        <h2>with a transformative discussion.</h2>
        <p className="book desk">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a
          <br />
          positive impact on your financial well-being.
        </p>
        <p className="book mob">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a positive impact on your financial well-being.
        </p>
        <div className="linkwrap">
          <a href="/contact">Contact us</a>
        </div>
        <hr />

        <p class="small desk">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA <span>|</span>{" "}
          Official credential directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
        <p class="small mob">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA. Official credential
          directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
      </footer>
    </div>
  );
}

export default Plan;
