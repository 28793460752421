import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import SliderG from "./SliderG";

function OurPage() {
  const [clickBurger, setClickBurger] = useState(false);
  const [index, setIndex] = useState(0); // State to track active slide index

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <div
        className={`site-head container-fluid ${clickBurger ? "transparent-bg color-me" : ""
          }`}
      >
        <div className="site-head--logo">
          <a className="logo" href="/">
            <img src="images/GanlazTwo.png" alt="img" />
          </a>
        </div>
        <div className="site-head--nav d-none d-lg-block">
          <nav className="menu-main-container">
            <ul id="menu-main" className="nav primary">
              <li
                id="menu-item-28"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28"
              >
                <a href="/you" className="menu__link">
                  You
                </a>
              </li>
              <li
                id="menu-item-27"
                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-16 current_page_item menu__item menu-item-27"
              >
                <a href="/us" className="menu__link">
                  Me
                </a>
              </li>
              <li
                id="menu-item-26"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26"
              >
                <a href="/plan" className="menu__link">
                  Plan
                </a>
              </li>
              <li
                id="menu-item-25"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25"
              >
                <a href="/invest" className="menu__link">
                  Invest
                </a>
              </li>
              <li
                id="menu-item-666"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666"
              >
                <a href="/pricing" className="menu__link">
                  Pricing
                </a>
              </li>
              <li
                id="menu-item-29"
                className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29"
              >
                <a href="/contact" className="menu__link">
                  Discover
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-head__mobile-menu d-block d-lg-none">
          <div
            onClick={() => setClickBurger(!clickBurger)}
            className="hamburger"
          >
            <span className={`hb-top ${clickBurger ? "shorter" : ""}`}></span>
            <span className="hb-middle"></span>
            <span className={`hb-bottom ${clickBurger ? "longer" : ""}`}></span>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${clickBurger ? "active" : ""}`}>
        <div className="mobile-menu__container container-fluid">
          <ul id="menu-main-1" className="mobile-menu__primary">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28">
              <a href="/you" className="menu__link">
                You
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-16 current_page_item menu__item menu-item-27">
              <a href="/us" className="menu__link">
                Me
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26">
              <a href="/plan" className="menu__link">
                Plan
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25">
              <a href="/invest" className="menu__link">
                Invest
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666">
              <a href="/pricing" className="menu__link">
                Pricing
              </a>
            </li>
            <li className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29">
              <a href="/contact" className="menu__link">
                Discover
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* main */}
      <main>
        <div id="section1">
          <div className=" page-blue hero-us hero-image">
            <div className="title-home">
              <h1
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <span>Me</span>
                {/* <p className="pp_sub">Meticulously</p> */}
              </h1>
              <a href="#section2" className="smooth">
                <img src="images/arrow-down.svg" alt="arrow pointing down" />
              </a>
            </div>
          </div>
        </div>

        <div id="" className="section about_ganlax_section">
          <section
            className="about_ganlax"
            data-color="blue"
          //    background-image: url('images/bg-question-centre.png');
          //   background-position: center;
          // style={{
          //   backgroundImage: "url('images/bg-vobis-left.png')",
          //   backgroundPosition: "center",
          // }}
          >
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              // style={{ fontSize: "2.8rem" }}
              className="invest_1st_p"
            >
              Presenting &quot;Ganlax,&quot; a name influenced by the Hindu deities Ganesh
              for wisdom and Laxmi for wealth. Devoted to our clients, we offer the
              insight needed for informed decisions and a prosperous future. Driven
              by a sincere passion, our goal is to positively impact your financial well-
              being. At Ganlax, you&#39;re not just a client; you&#39;re on a journey with us
              toward financial wisdom and prosperity.
            </p>
            <div className="hero-arrow--wrap color-slides">
              <a href="#section2" className="smooth hero-arrow navi">
                <img src="images/arrow-down.svg" alt="arrow pointing down" />
              </a>
              <a href="#footer" className="hero-arrow footer-link">
                <img src="images/arrow-down.svg" alt="arrow pointing down" />
              </a>
            </div>
          </section>
        </div>

        <div id="section2">
          <div className="text_with_title">
            <div className="text_with_title--row">
              <div className="max-width text">
                <h2
                  className="us-page"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200
        "
                >
                  {/* Our focus on people doesn’t end with you. */}
                  Meet Bhavesh Patel: A Financial Maestro
                </h2>
              </div>
            </div>
            {/* <div className="text_with_title--row">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott.png 400w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott-300x300.png 300w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott-150x150.png 150w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive"
                  alt="img"
                />
              </div>
              <div
                className="para max-width text blue"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <p>
                  Introducing Bhavesh Patel, a retired ex-Investment Banker with
                  an impressive 20-year career at prestigious institutions like
                  JP Morgan, Barclays, and Standard Chartered.
                </p>
                <p>
                  As a Fellowship holder with the Chartered Institute of
                  Management Accountants and a certified Financial Risk
                  Management professional, Bhavesh combines strategic insight
                  with academic excellence, holding a 1st Class Degree in
                  Mathematical Sciences.
                </p>
                <p>
                  His seasoned expertise makes him a standout figure in the
                  financial realm.
                </p>
                <p>
                  In his spare time, Bhavesh is a keen explorer, having hiked
                  Mont Blanc, cycled to Paris, and sailed across the Atlantic.
                  His adventurous spirit extends to a love for films, where he
                  appreciates the artistry and storytelling that captivates
                  audiences. In addition, Bhavesh loves sport and has a passion
                  for diverse vegetarian cuisine.
                </p>
                <p>
                  Bhavesh's multifaceted interests reflect a well-rounded
                  individual who not only excels in the professional domain but
                  also embraces the richness of life beyond the financial world.
                </p>
              </div>
            </div> */}
            <div
              style={{ marginTop: "25px" }}
              className="row align-items-center row-reverse max-width box"
            >
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="/images/user1.jpeg"
                  srcset="/images/user1.jpeg 400w, /images/user1.jpeg 300w,/images/user1.jpeg 150w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive new_img_user"
                  alt="img"
                />
              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                {/* <h3 className="color-green">Richard Stott</h3> */}
                <p className="mancs">
                  Introducing Bhavesh Patel, a retired ex-Investment Banker with
                  an impressive 20-year career at prestigious institutions like
                  JP Morgan, Barclays, and Standard Chartered.
                </p>
                <p className="mancs">
                  As a Fellowship holder with the Chartered Institute of
                  Management Accountants and a certified Financial Risk
                  Management professional, Bhavesh combines strategic insight
                  with academic excellence, holding a 1st Class Degree in
                  Mathematical Sciences.
                </p>
                <p className="mancs">
                  His seasoned expertise makes him a standout figure in the
                  financial realm.
                </p>
                <p className="mancs">
                  In his spare time, Bhavesh is a keen explorer, having hiked
                  Mont Blanc, cycled to Paris, and sailed across the Atlantic.
                  His adventurous spirit extends to a love for films, where he
                  appreciates the artistry and storytelling that captivates
                  audiences. In addition, Bhavesh loves sport and has a passion
                  for diverse vegetarian cuisine.
                </p>
                <p className="mancs">
                  Bhavesh's multifaceted interests reflect a well-rounded
                  individual who not only excels in the professional domain but
                  also embraces the richness of life beyond the financial world.
                </p>
              </div>

              <hr className="color-green" />
            </div>
          </div>
        </div>

        <div id="section3">
          <div className="image_with_text extra_padding">
            <img
              src="images/us-img-min.jpeg"
              sizes="(max-width: 1920px) 100vw, 1920px"
              alt="img"
            />
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              {/* We help clients improve the world in which they live */}
              We coach and mentor our clients to be confident investors and
              improve their financial well-being.
            </h2>
          </div>
        </div>

        {/* <div id="section4">
          <div className="carousel-wrap">
            <div
              id="tc"
              className="testimonial carousel slide carousel-fade"
              data-ride="true"
              data-interval="7000"
              data-pause="false"
            >
              <h2>VALUES</h2>
              <hr />
              <div className="carousel-inner">
                <div className="carousel-item">
                  <div className="carousel-item--text-wrap">
                    <h4>Importance of Money</h4>
                    <p></p>
                    <p>
                      In the pursuit of financial well-being, the significance
                      of money cannot be overstated. It's a product of your hard
                      work and dedication that has brought you to this pivotal
                      position. Protecting and growing your wealth is a
                      paramount concern, and I am here to provide coaching
                      tailored to safeguard and enhance your financial assets.
                    </p>
                    <p></p>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="carousel-item--text-wrap">
                    <h4>Trust through Experience</h4>
                    <p></p>
                    <p>
                      Trust is a foundation built on experience, and with 20
                      years of profound expertise in Investment Banking, I bring
                      a wealth of knowledge to the table. Managing a personal
                      portfolio of $6 million, encompassing stocks, bonds, VCTs,
                      and startups, I coach based on practices that I personally
                      employ and invest in. Your trust is not just earned; it's
                      diligently maintained.
                    </p>
                    <p></p>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="carousel-item--text-wrap">
                    <h4>Humble Beginnings, Shared Journey</h4>
                    <p></p>
                    <p>
                      I resonate with the journey many undertake, starting with
                      humble beginnings and gradually navigating the
                      complexities of life. Similar to you, I have worked
                      tirelessly, invested strategically, and reached a point
                      where I am ready to impart the wisdom and knowledge
                      accumulated along the way.
                    </p>
                    <p></p>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="carousel-item--text-wrap">
                    <h4>Evolution of Financial Stages</h4>
                    <p></p>
                    <p>
                      As we evolve, so do our goals and aspirations. Recognising
                      this dynamic nature, the investment landscape must adapt
                      over time. At the heart of my approach is a commitment to
                      helping you tailor your financial strategies to align
                      seamlessly with the evolving stages of your life, ensuring
                      your financial plan remains a steadfast support throughout
                      your journey.
                    </p>
                    <p></p>
                    
                  </div>
                </div>

             
              </div>

              <ol className="carousel-indicators">
                <li data-target="#tc" data-slide-to="0" className="button">
                  1
                </li>
                <li data-target="#tc" data-slide-to="1" className="button">
                  2
                </li>
                <li data-target="#tc" data-slide-to="2" className="button">
                  3
                </li>
                <li data-target="#tc" data-slide-to="3" className="button">
                  4
                </li>
              </ol>
            </div>
          </div>

         
        </div> */}

        <div id="section4">
          <div className="carousel-wrap">
            <div className="testimonial">
              <h2>VALUES</h2>
              <hr />
            </div>
            <SliderG />
          </div>
        </div>

        {/* <div id="section5">
          <div className="ganlax_feedback">
            <h2 className="max-width box">
              Here are the key individuals to support your journey.
            </h2>

            <div className="row align-items-center row-reverse max-width box">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott.png 400w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott-300x300.png 300w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2020/07/20165032/RichardScott-150x150.png 150w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive"
                  alt="img"
                />

              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-green">Richard Stott</h3>
                <p>
                  Richard is the founder of Vobis. He heads up the management
                  team and sits on the Investment Committee. Richard also
                  oversees the wealth advisory team and plays a key role with
                  Vobis clients.
                </p>
                <p>
                  Vobis is more than a job for Richard. It’s his passion, and
                  his way of life. Richard’s speciality is wealth and investment
                  strategies that are relevant today and can adapt for tomorrow.
                  He supports personal, family and business decision making.
                </p>
                <p>
                  Hailing from the North himself, Richard is very partial to
                  Yorkshire and everything it brings to the world. He’s also a
                  collector of minerals and follows the philosophy of Stoicism.
                </p>
              </div>

              <hr className="color-green" />
            </div>

            <div className="row align-items-center row-reverse max-width box">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151702/steve_henley.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151702/steve_henley.png 400w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151702/steve_henley-300x300.png 300w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151702/steve_henley-150x150.png 150w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive"
                  alt="img"
                />

              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-brown">Steve Henley</h3>
                <p>
                  Steve is the Managing Director of the business. He’s also the
                  Head of Compliance and a member of the Investment Committee.
                  Steve oversees the running of the business ensuring that the
                  company and the staff are adhering to the regulatory regime as
                  well as preparing for any future changes.
                </p>
                <p>
                  For Steve, the best thing about working at Vobis is the
                  possibility of shaping the future, not only for their clients
                  but also the company and their investments.
                </p>
                <p>
                  Outside the office Steve is a keen golfer and is also
                  interested in all internal martial arts.
                </p>
              </div>

              <hr className="color-brown" />
            </div>

            <div className="row align-items-center row-reverse max-width box">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151835/sarah_lockington1.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151835/sarah_lockington1.png 400w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151835/sarah_lockington1-300x300.png 300w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25151835/sarah_lockington1-150x150.png 150w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive"
                  alt="img"
                />

              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-blue">Sarah Lockington</h3>
                <p>
                  Our Wealth Manager Sarah heads up the Investment Committee and
                  our in-house investment team, as well as overseeing and
                  running the in-house portfolios. She builds portfolios to meet
                  the needs of our clients, with an emphasis on thematic and
                  sustainable investing.
                </p>
                <p>
                  Sarah’s job enables her to indulge in two passions: financial
                  markets and acting as a ‘guardian of the planet’ for future
                  generations, investing in environmental and social solutions
                  that generate both positive return and measurable positive
                  impact on the planet.
                </p>
                <p>
                  Sarah is country bumpkin based in London, and she spends as
                  much time as possible in the great outdoors with her wonderful
                  dog. She has also completed a Half IronMan!
                </p>
              </div>

              <hr className="color-blue" />
            </div>

            <div className="row align-items-center row-reverse max-width box">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25144844/shannon_hubbard2.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25144844/shannon_hubbard2.png 800w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25144844/shannon_hubbard2-300x300.png 300w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25144844/shannon_hubbard2-150x150.png 150w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2021/01/25144844/shannon_hubbard2-768x768.png 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive"
                />

                <img src="images/shannon_hubbard2.png" alt="" />
              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-green">Shannon Hubbard</h3>
                <p>
                  Shannon is the Executive Assistant for Richard Stott. She
                  provides general support to the investment team, and is
                  responsible for the day-to-day operation and administration of
                  Vobis. Shannon also supports the company directors and board
                  members and manages diaries, organises meetings, and assists
                  with client requests.
                </p>
                <p>
                  As part of her job, Shannon is able to develop and maintain
                  strong client relationships and liaise with different people
                  every day. She enjoys working for a family-run business and
                  alongside a dedicated team who share the same fundamental
                  values.
                </p>
                <p>
                  As a fitness enthusiast, Shannon plays football for her local
                  town and is always in the gym. She recently completed the
                  Yorkshire 3 peaks challenge!
                </p>
              </div>

              <hr className="color-green" />
            </div>

            <div className="row align-items-center row-reverse max-width box">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14164918/Katie-Cornes-OL-1.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14164918/Katie-Cornes-OL-1.png 800w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14164918/Katie-Cornes-OL-1-300x300.png 300w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14164918/Katie-Cornes-OL-1-150x150.png 150w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14164918/Katie-Cornes-OL-1-768x768.png 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive"
                  alt="img"
                />

              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-orange">Katie Cornes</h3>
                <p>
                  Although she’s achieved her Diploma in Financial Planning
                  Katie continues to study and learn. Katie believes that
                  effective planning requires her to be as knowledgable as
                  possible, besides, she likes to learn.
                </p>
                <p>
                  Katie works closely with our advisers, carrying out wide
                  research to make sure we recommend truly beneficial investment
                  strategies. Katie says the favourite part of her job is
                  meeting new clients and getting to know them, because building
                  trusting relationships is vital.
                </p>
                <p>
                  Katie has played Netball since she was 11 years old, and even
                  competed at national level where her team came 8th in England.
                  She currently plays in her county league for the Jets.
                </p>
              </div>

              <hr className="color-orange" />
            </div>

            <div className="row align-items-center row-reverse max-width box">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14165119/Tom-Randall-BR.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14165119/Tom-Randall-BR.png 800w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14165119/Tom-Randall-BR-300x300.png 300w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14165119/Tom-Randall-BR-150x150.png 150w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14165119/Tom-Randall-BR-768x768.png 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  alt="img"
                  className="img-responsive"
                />

              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-brown">Tom Randall</h3>
                <p>
                  Clients come to Tom with their short, medium and long term
                  aspirations. It’s then Tom’s role to integrate financial
                  advice into their lives to help them achieve their goals. He
                  loves to take away to complexities of advice for clients and
                  business owners, building relationships along the way.
                </p>
                <p>
                  Tom sees his main job as protecting individuals and their
                  families, then facilitating the creation of wealth. He does
                  this through sustainable and growth opportunity investing. And
                  ultimately helping clients to pass on their wealth to the next
                  generation.
                </p>
                <p>
                  “The wonderfully varied nature of my clients and the
                  industries they work in is fascinating and I learn something
                  new from each and every one of them.”
                </p>
              </div>

              <hr className="color-brown" />
            </div>

            <div className="row align-items-center row-reverse max-width box">
              <div
                className="col-12 col-lg-4 ganlax_feedback--image"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14171928/Lucy-Whyment-GL.png"
                  srcset="https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14171928/Lucy-Whyment-GL.png 800w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14171928/Lucy-Whyment-GL-150x150.png 150w, https://assets.plottcdn.co.uk/vobis/wp-content/uploads/2022/09/14171928/Lucy-Whyment-GL-768x772.png 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                  className="img-responsive"
                  alt="img"
                />

                <img src="images/Lucy-Whyment-GL.png" alt="" />
              </div>

              <div
                className="col-12 col-lg-8 ganlax_feedback--profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-green">Lucy Whyment</h3>
                <p>
                  Administrator Lucy is a Business and Finance graduate who’s
                  day-to-day activity includes handling inbound client queries,
                  preparing meeting packs, and assisting advisers and
                  paraplanners. Further to this Lucy uses her education and
                  experience in research and analysis when reviewing or
                  constructing new portfolios.
                </p>
                <p>
                  She’s a liaison between advisers, providers and clients,
                  keeping everyone informed and on track. For clients financial
                  matters can be an emotional activity at times. To alleviate
                  this Lucy finds transparent, open communication can put a
                  client’s mind at ease. She shows the very best of Vobis, and
                  reassures our clients that they’re in good hands.
                </p>
                <p>
                  Lucy maintains a keen interest in art, coming as she does from
                  an artistic family. The most notable family artist is Vincent
                  Haddelsey, who painted landscapes, often featuring horses.
                  She’d love to have her own studio someday where she could
                  paint pottery.
                </p>
              </div>

              <hr className="color-green" />
            </div>
          </div>
        </div> */}

        <div id="section6">
          <div className="section_links max-width box">
            <div className="row">
              <div
                className="col-12 col-lg-6 match"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="section section_orange match">
                  <a href="/plan"></a>
                  <h3>Plan</h3>
                  <p>
                    Our wealth planning strategies are crafted to align with
                    your current lifestyle and evolve alongside your growing
                    wealth. We assist you in identifying your priorities and
                    create a flexible plan that can adapt seamlessly as you
                    navigate life's transitions.
                  </p>
                  <img src="images/arrow-right.svg" alt="img" />
                </div>
              </div>

              <div
                className="col-12 col-lg-6 match"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="section section_invest match">
                  <a href="/invest"></a>
                  <h3>Invest</h3>
                  <p>
                    We continuously adjust our investment strategies to
                    concentrate on global and innovative themes. By exclusively
                    investing in companies that embrace change and impact our
                    daily lives, we've delivered substantial returns for both
                    our investors and ourselves.
                  </p>
                  <img src="images/arrow-right.svg" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* footer */}
      <footer id="footer" className="page_builder">
        <img src="images/GanlazIconTwo-pre.svg" alt="ganlaz logo" />
        <h2>Embark on a</h2>
        <h2>new financial journey </h2>
        <h2>with a transformative discussion.</h2>
        <p className="book desk">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a
          <br />
          positive impact on your financial well-being.
        </p>
        <p className="book mob">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a positive impact on your financial well-being.
        </p>
        <div className="linkwrap">
          <a href="/contact">Contact us</a>
        </div>
        <hr />

        <p class="small desk">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA <span>|</span>{" "}
          Official credential directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
        <p class="small mob">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA. Official credential
          directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
      </footer>
    </div>
  );
}

export default OurPage;
