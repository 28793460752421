import React, { useEffect, useState } from "react";
import "./contact.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [clickBurger, setClickBurger] = useState(false);
  // hello code

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      name,
      email,
      phone,
      message,
    };

    fetch(`${window.location.origin}/email.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).catch((err) => console.log(err));

    setIsSuccess(true);
  };

  return (
    <div>
      <div
        className={`site-head container-fluid contact-grey ${
          clickBurger ? "transparent-bg color-me" : ""
        }`}
      >
        <div className="site-head--logo">
          <a className="logo" href="/">
            <img src="images/GanlazTwo.png" alt="img" />
          </a>
        </div>
        <div className="site-head--nav d-none d-lg-block">
          <nav className="menu-main-container">
            <ul id="menu-main" className="nav primary">
              <li
                id="menu-item-28"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28"
              >
                <a href="/you" className="menu__link">
                  You
                </a>
              </li>
              <li
                id="menu-item-27"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27"
              >
                <a href="/us" className="menu__link">
                  Me
                </a>
              </li>
              <li
                id="menu-item-26"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26"
              >
                <a href="/plan" className="menu__link">
                  Plan
                </a>
              </li>
              <li
                id="menu-item-25"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25"
              >
                <a href="/invest" className="menu__link">
                  Invest
                </a>
              </li>
              <li
                id="menu-item-666"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666"
              >
                <a href="/pricing" className="menu__link">
                  Pricing
                </a>
              </li>
              <li
                id="menu-item-29"
                className="bubble menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-22 current_page_item menu__item menu-item-29"
              >
                <a href="/contact" className="menu__link">
                  Discover
                </a>
              </li>
            </ul>
          </nav>{" "}
        </div>
        <div className="site-head__mobile-menu d-block d-lg-none">
          <div
            onClick={() => setClickBurger(!clickBurger)}
            className="hamburger"
          >
            <span className={`hb-top ${clickBurger ? "shorter" : ""}`}></span>
            <span className="hb-middle"></span>
            <span className={`hb-bottom ${clickBurger ? "longer" : ""}`}></span>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${clickBurger ? "active" : ""}`}>
        <div className="mobile-menu__container container-fluid">
          <ul id="menu-main-1" className="mobile-menu__primary">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28">
              <a href="/you" className="menu__link">
                You
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27">
              <a href="/us" className="menu__link">
                Me
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26">
              <a href="/plan" className="menu__link">
                Plan
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25">
              <a href="/invest" className="menu__link">
                Invest
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666">
              <a href="/pricing" className="menu__link">
                Pricing
              </a>
            </li>
            <li className="bubble menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-22 current_page_item menu__item menu-item-29">
              <a href="/contact" className="menu__link">
                Discover
              </a>
            </li>
          </ul>{" "}
        </div>
      </div>

      {/* main contact */}

      <main>
        <section
          id="section1"
          className="hero-image"
          data-color="grey"
          style={{
            backgroundImage: `url('')`,
            backgroundPosition: `center`,
          }}
        >
          <div
            className="image-wrap--img"
            style={{
              backgroundImage: `url('images/contact-1.jpg')`,
            }}
          ></div>
          <div
            className="title-home contact-page"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <h1 style={{ textAlign: "center" }}>
              Our adventure begins with a discovery conversation tailored to you
            </h1>
            <a href="#address" className="smooth">
              <img src="images/arrow-down.svg" alt="arrow pointing down" />
            </a>
          </div>
        </section>

        <div className="contact-wrap" id="address">
          <div className="contact-inner max-width text">
            <div className="row align-items-center">
              <address
                className="col-12 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <h1>
                  {/* Can you <br /> link it to */}
                  Fancy a chat?
                </h1>
                <p className="mancs">
                  Secure your complimentary discovery session with us, and let's
                  explore how we can make a positive impact on your financial
                  well-being.
                </p>
                <h2 className="tel">
                  <a href="tel:07595357976">07595357976 </a>
                </h2>
                <h2 className="tel admin">
                  Email.{" "}
                  <a href="mailto:investing@ganlaxgroup.co.uk ">
                    investing@ganlaxgroup.co.uk{" "}
                  </a>
                </h2>
              </address>

              <div
                className="col-12 col-lg-6 contact-form-wrapper"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="350"
              >
                <div
                  className="gf_browser_safari gform_wrapper gform_legacy_markup_wrapper gform-theme--no-framework"
                  data-form-theme="legacy"
                  data-form-index="0"
                  id="gform_wrapper_1"
                >
                  <div id="gf_1" className="gform_anchor" tabindex="-1"></div>
                  {isSuccess ? (
                    <div className="success">
                      <h1>Thank you for your message</h1>
                      <p>We will be in touch soon.</p>
                    </div>
                  ) : (
                    <form
                      // method="post"
                      // enctype="multipart/form-data"
                      // target="gform_ajax_frame_1"
                      id="gform_1"
                      // action="/contact/#gf_1"
                      // data-formid="1"
                      onSubmit={submitForm}
                    >
                      <div className="gf-recaptcha-div"></div>
                      <div className="gform-body gform_body">
                        <ul
                          id="gform_fields_1"
                          className="gform_fields top_label form_sublabel_below description_below"
                        >
                          <li
                            id="field_1_1"
                            className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible"
                            data-js-reload="field_1_1"
                          >
                            <label
                              className="gfield_label gform-field-label"
                              for="input_1_1"
                            >
                              Your name
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_text">
                              <input
                                name="name"
                                id="input_1_1"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="medium"
                                placeholder="Your name"
                                aria-required="true"
                                aria-invalid="false"
                                required
                              />{" "}
                            </div>
                          </li>
                          <li
                            id="field_1_2"
                            className="gfield gfield--type-email gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible"
                            data-js-reload="field_1_2"
                          >
                            <label
                              className="gfield_label gform-field-label"
                              for="input_1_2"
                            >
                              Your email address
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_email">
                              <input
                                name="email"
                                id="input_1_2"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="medium"
                                placeholder="Your email address"
                                aria-required="true"
                                aria-invalid="false"
                                required
                              />
                            </div>
                          </li>
                          <li
                            id="field_1_3"
                            className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible"
                            data-js-reload="field_1_3"
                          >
                            <label
                              className="gfield_label gform-field-label"
                              for="input_1_3"
                            >
                              Your telephone number
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_text">
                              <input
                                name="input_3"
                                id="input_1_3"
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="medium"
                                placeholder="Your telephone number"
                                aria-required="true"
                                aria-invalid="false"
                                required
                              />{" "}
                            </div>
                          </li>
                          <li
                            id="field_1_5"
                            className="gfield gfield--type-textarea gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible"
                            data-js-reload="field_1_5"
                          >
                            <label
                              className="gfield_label gform-field-label"
                              for="input_1_5"
                            >
                              Your message
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_textarea">
                              <textarea
                                name="input_5"
                                id="input_1_5"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="textarea medium"
                                placeholder="Your message"
                                aria-required="true"
                                aria-invalid="false"
                                rows="10"
                                cols="50"
                                required
                              ></textarea>
                            </div>
                          </li>
                          <li
                            id="field_1_6"
                            className="gfield gfield--type-consent gfield--type-choice gfield--input-type-consent checkplot gfield_contains_required field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible"
                            data-js-reload="field_1_6"
                          >
                            <label className="gfield_label gform-field-label gfield_label_before_complex">
                              Consent
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_consent">
                              <input
                                name="input_6.1"
                                id="input_1_6_1"
                                type="checkbox"
                                value="1"
                                aria-required="true"
                                aria-invalid="false"
                              />{" "}
                              {/* <label
                                className="gform-field-label gform-field-label--type-inline gfield_consent_label"
                                for="input_1_6_1"
                              >
                                I agree to the{" "}
                                <a target="_blank" href="/privacy/">
                                  privacy policy
                                </a>
                                .
                              </label> */}
                              <input
                                type="hidden"
                                name="input_6.2"
                                value="I agree to the"
                              />
                              {/* <a target=" _blank"="" href="/privacy/">privacy policy." className="gform_hidden" ></a> */}
                              <input
                                type="hidden"
                                name="input_6.3"
                                value="1"
                                className="gform_hidden"
                              />
                            </div>
                          </li>
                          <li
                            id="field_1_7"
                            className="gfield gfield--type-html gfield_html gfield_html_formatted gfield_no_follows_desc field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible"
                            data-js-reload="field_1_7"
                          >
                            <button
                              type="submit"
                              className="plott-loading--button ld-ext-right contact"
                              // onclick="this.classList.add('running')"
                              id="gform_submit_button_1"
                            >
                              Send message
                              <div className="ld ld-ring ld-spin-fast"></div>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="gform_footer top_label">
                        {" "}
                        <input
                          type="hidden"
                          name="gform_ajax"
                          value="form_id=1&amp;title=&amp;description=&amp;tabindex=0&amp;theme=data-form-theme='legacy'"
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="is_submit_1"
                          value="1"
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_submit"
                          value="1"
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_unique_id"
                          value=""
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="state_1"
                          value="WyJ7XCI2LjFcIjpcImI3MTE4NjYyYWE2ODg2MDlmMmUwNmY0YjYyODhlYTZhXCIsXCI2LjJcIjpcIjBiZGUwZTgyOTZiNDAxM2I5MTI1MmM4NmIzNGQxY2UxXCIsXCI2LjNcIjpcImI3MTE4NjYyYWE2ODg2MDlmMmUwNmY0YjYyODhlYTZhXCJ9IiwiYmY4YmU0NGQ5OTcxNDIyYjI1ZDhjM2Y3OTcwY2JhMGMiXQ=="
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_target_page_number_1"
                          id="gform_target_page_number_1"
                          value="0"
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_source_page_number_1"
                          id="gform_source_page_number_1"
                          value="1"
                        />
                        <input
                          type="hidden"
                          name="gform_field_values"
                          value=""
                        />
                      </div>
                      <p
                        style={{
                          display: "none !important",
                        }}
                      >
                        <label>
                          Δ
                          <textarea
                            name="ak_hp_textarea"
                            cols="45"
                            rows="8"
                            maxlength="100"
                          ></textarea>
                        </label>
                        <input
                          type="hidden"
                          id="ak_js_1"
                          name="ak_js"
                          value="1700345743826"
                        />
                        <script>
                          document.getElementById( "ak_js_1" ).setAttribute(
                          "value", ( new Date() ).getTime() );
                        </script>
                      </p>
                    </form>
                  )}
                </div>
              </div>

              {/* end */}
            </div>
          </div>
        </div>

        <footer className="">
          <hr />
          {/* <nav className="menu-footer-container">
            <ul id="menu-footer" className="footer">
              <li
                id="menu-item-127"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-127"
              >
                <a
                  href="https://ganlaxgroup.co.uk/legal/cookies/"
                  className="menu__link"
                >
                  Cookies
                </a>
              </li>
              <li
                id="menu-item-128"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-128"
              >
                <a
                  href="https://ganlaxgroup.co.uk/legal/terms/"
                  className="menu__link"
                >
                  Terms
                </a>
              </li>
              <li
                id="menu-item-129"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-129"
              >
                <a
                  href="https://ganlaxgroup.co.uk/legal/privacy/"
                  className="menu__link"
                >
                  Privacy
                </a>
              </li>
              <li
                id="menu-item-51"
                className="menu-item menu-item-type-custom menu-item-object-custom menu__item menu-item-51"
              >
                <a
                  target="_blank"
                  href="https://www.plottcreative.co.uk/"
                  className="menu__link"
                  rel="noreferrer"
                >
                  Site by PLOTT
                </a>
              </li>
            </ul>
          </nav> */}
          <p className="small desk">
            Bhavesh Patel, the director of Ganlax Investments Limited, is a
            Fellowship holder and regulated by AICPA & CIMA <span>|</span>{" "}
            Official credential directory can be found{" "}
            <a
              style={{
                color: "#fff",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              target="_blank"
              href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
            >
              here
            </a>
            .
            <br />
            Registered in England and Wales No. 10778074
          </p>
          <p className="small mob">
            Bhavesh Patel, the director of Ganlax Investments Limited, is a
            Fellowship holder and regulated by AICPA & CIMA. Official credential
            directory can be found{" "}
            <a
              style={{
                color: "#fff",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              target="_blank"
              href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
            >
              here
            </a>
            .
            <br />
            Registered in England and Wales No. 10778074
          </p>
        </footer>
      </main>
    </div>
  );
}

export default Contact;
