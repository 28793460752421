import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";

// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

export default function SliderInvest() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `
      <span class="${className}"> ${index + 1} </span>
      `;
    },
  };

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={pagination}
        // navigation={true}
        modules={[EffectFade, Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slider-bg">
            <div className="testimonial">
              <div className="carousel-item--text-wrap">
                <h4>Risk Profile Assessment</h4>
                <p></p>
                <p>
                  We assess your risk profile, tolerance, and capacity through a
                  comprehensive questionnaire. The risk score we assign is the
                  result of research conducted by leading academics in
                  financial-risk decision making and behavioural psychology. Our
                  approach combines elements of validated psychometrics and
                  predictive behavioural lotteries based on Prospect Theory,
                  leveraging the strengths of each to mitigate inherent
                  weaknesses.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-bg">
            <div className="testimonial">
              <div className="carousel-item--text-wrap">
                <h4>Asset Allocation Strategy</h4>
                <p></p>
                <p>
                  Based on your unique risk profile, we meticulously determine a
                  suitable asset allocation. Recognizing that no two clients are
                  identical, we tailor this allocation to each individual's
                  distinct profile. Our belief is that this personalized
                  approach is essential to accurately reflect the diversity of
                  our clients.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-bg">
            <div className="testimonial">
              <div className="carousel-item--text-wrap">
                <h4>Investment Selection Process</h4>
                <p></p>
                <p>
                  We take a proactive stance in recommending the best funds
                  tailored to each client's specific needs. Our approach not
                  only incorporates technical asset allocation derived from
                  their behavioural risk score but also considers upcoming life
                  needs. Life is anything but vanilla, and our fund selection
                  process mirrors this diversity, ensuring a comprehensive and
                  personalized investment strategy.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
