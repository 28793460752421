import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";

// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

export default function SliderG() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `
      <span class="${className}"> ${index + 1} </span>
      `;
    },
  };

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={pagination}
        // navigation={true}
        modules={[EffectFade, Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slider-bg">
            <div className="testimonial">
              <div className="carousel-item--text-wrap">
                <h4>Importance of Money</h4>
                <p>
                  In the pursuit of financial well-being, the significance of
                  money cannot be overstated. It's a product of your hard work
                  and dedication that has brought you to this pivotal position.
                  Protecting and growing your wealth is a paramount concern, and
                  I am here to provide coaching tailored to safeguard and
                  enhance your financial assets.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-bg">
            <div className="testimonial">
              <div className="carousel-item--text-wrap">
                <h4>Trust through Experience</h4>
                <p>
                  Trust is a foundation built on experience, and with 20 years
                  of profound expertise in Investment Banking, I bring a wealth
                  of knowledge to the table. Managing a personal portfolio of $6
                  million, encompassing stocks, bonds, VCTs, and startups, I
                  coach based on practices that I personally employ and invest
                  in. Your trust is not just earned; it's diligently maintained.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-bg">
            <div className="testimonial">
              <div className="carousel-item--text-wrap">
                <h4>Humble Beginnings, Shared Journey</h4>
                <p>
                  I resonate with the journey many undertake, starting with
                  humble beginnings and gradually navigating the complexities of
                  life. Similar to you, I have worked tirelessly, invested
                  strategically, and reached a point where I am ready to impart
                  the wisdom and knowledge accumulated along the way.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-bg">
            <div className="testimonial">
              <div className="carousel-item--text-wrap">
                <h4>Evolution of Financial Stages</h4>
                <p>
                  As we evolve, so do our goals and aspirations. Recognising
                  this dynamic nature, the investment landscape must adapt over
                  time. At the heart of my approach is a commitment to helping
                  you tailor your financial strategies to align seamlessly with
                  the evolving stages of your life, ensuring your financial plan
                  remains a steadfast support throughout your journey.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
