import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import lottie from "lottie-web";
import animationData from "../assets/ganzal.json";
// import bodymovin from "bodymovin";
import video from "../assets/media/home-video.mp4";
import poster from "../assets/media/video_cover.jpg";

function Home() {
  const lotiRef = useRef(null);
  const [clickBurger, setClickBurger] = useState(false);
  console.log("lotiRef: ", lotiRef);

  useEffect(() => {
    // Referencing the container element
    // const container = document.getElementById("preloader-file");
    // console.log("container: ", container);

    // Load the animation
    if (lotiRef.current) {
      lottie.loadAnimation({
        container: lotiRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  }, []);

  //   useEffect(() => {
  //     const container = document.getElementById("preloader-file");
  //     console.log("container: ", container);

  //     if (!container) {
  //       console.error("Container element not found.");
  //       return;
  //     }

  //     const animation = bodymovin.loadAnimation({
  //       container: container,
  //       renderer: "svg",
  //       loop: true,
  //       autoplay: true,
  //       path: "https://assets.plottcdn.co.uk/vobis/vobis_preloader.json",
  //     });

  //     return () => {
  //       animation.destroy();
  //     };
  //   }, []);

  //   useEffect(() => {
  //     const t = setTimeout(() => {
  //       console.log("lotiRef: ", lotiRef);
  //     }, 300);
  //     return () => clearTimeout(t);
  //   }, []);

  return (
    <>
      <div className="preloder">
        <div id="preloder-file" ref={lotiRef}></div>
      </div>

      {/* <div className="preloder">
        <img
          className="preloder-Img"
          src="images/GanlazIconTwo-pre.svg"
          alt="ganlaz"
          style={{ width: "150px", height: "150px" }}
        />
      </div> */}

      <div className="Home">
        <div
          className={`site-head container-fluid head- ${
            clickBurger ? "transparent-bg color-me" : ""
          }`}
        >
          <div className="site-head--logo">
            <a className="logo" href="/">
              <img alt="img" src="images/GanlazTwo.png" />
            </a>
          </div>
          <div className="site-head--nav d-none d-lg-block">
            <nav className="menu-main-container">
              <ul id="menu-main" className="nav primary">
                <li
                  id="menu-item-28"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28"
                >
                  <a href="/you" className="menu__link">
                    You
                  </a>
                </li>
                <li
                  id="menu-item-27"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27"
                >
                  <a href="/us" className="menu__link">
                    Me
                  </a>
                </li>
                <li
                  id="menu-item-26"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26"
                >
                  <a href="/plan" className="menu__link">
                    Plan
                  </a>
                </li>
                <li
                  id="menu-item-25"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25"
                >
                  <a href="/invest" className="menu__link">
                    Invest
                  </a>
                </li>
                {/* <li
                  id="menu-item-666"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666"
                >
                  <a href="/insights" className="menu__link">
                    Insights
                  </a>
                </li> */}
                <li
                  id="menu-item-666"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666"
                >
                  <a href="/pricing" className="menu__link">
                    Pricing
                  </a>
                </li>
                <li
                  id="menu-item-29"
                  className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29"
                >
                  <a href="/contact" className="menu__link">
                    Discover
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-head__mobile-menu d-block d-lg-none">
            <div
              onClick={() => setClickBurger(!clickBurger)}
              className="hamburger"
            >
              <span className={`hb-top ${clickBurger ? "shorter" : ""}`}></span>
              <span className="hb-middle"></span>
              <span
                className={`hb-bottom ${clickBurger ? "longer" : ""}`}
              ></span>
            </div>
          </div>
        </div>

        <div className={`mobile-menu ${clickBurger ? "active" : ""}`}>
          <div className="mobile-menu__container container-fluid">
            <ul id="menu-main-1" className="mobile-menu__primary">
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28">
                <a href="/you" className="menu__link">
                  You
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27">
                <a href="/us" className="menu__link">
                  Me
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26">
                <a href="/plan" className="menu__link">
                  Plan
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25">
                <a href="/invest" className="menu__link">
                  Invest
                </a>
              </li>
              {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666">
                <a href="/insights" className="menu__link">
                  Insights
                </a>
              </li> */}
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666">
                <a href="/pricing" className="menu__link">
                  Pricing
                </a>
              </li>
              <li className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29">
                <a href="/contact" className="menu__link">
                  Discover
                </a>
              </li>
            </ul>
          </div>
        </div>

        <main>
          <header className="bg-video">
            <video
              id="mmm"
              className=""
              autoPlay
              muted
              loop
              playsInline
              poster={poster}
            >
              <source src={video} type="video/mp4" />
            </video>
          </header>

          <div className="title-home">
            {/* <h1>What’s important to you?</h1> */}
            <h1>
              Empowering Futures, Transforming Lives: One Financial Leap at a
              Time!
            </h1>
            <a className="title-link" href="/you">
              Lets get started
            </a>
          </div>
        </main>
      </div>
    </>
  );
}

export default Home;
