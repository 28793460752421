import React, { useEffect, useState } from "react";
import "./invest.css";
import AOS from "aos";
import "aos/dist/aos.css";
import SliderInvest from "./SliderInvest";

function Invest() {
  const [clickBurger, setClickBurger] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <div
        className={`site-head container-fluid head- ${
          clickBurger ? "transparent-bg color-me" : ""
        }`}
      >
        <div className="site-head--logo">
          <a className="logo" href="/">
            <img src="images/GanlazTwo.png" alt="img" />
          </a>
        </div>
        <div className="site-head--nav d-none d-lg-block">
          <nav className="menu-main-container">
            <ul id="menu-main" className="nav primary">
              <li
                id="menu-item-28"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28"
              >
                <a href="/you" className="menu__link">
                  You
                </a>
              </li>
              <li
                id="menu-item-27"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27"
              >
                <a href="/us" className="menu__link">
                  Me
                </a>
              </li>
              <li
                id="menu-item-26"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26"
              >
                <a href="/plan" className="menu__link">
                  Plan
                </a>
              </li>
              <li
                id="menu-item-25"
                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-20 current_page_item menu__item menu-item-25"
              >
                <a href="/invest" className="menu__link">
                  Invest
                </a>
              </li>
              <li
                id="menu-item-666"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666"
              >
                <a href="/pricing" className="menu__link">
                  Pricing
                </a>
              </li>
              <li
                id="menu-item-29"
                className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29"
              >
                <a href="/contact" className="menu__link">
                  Discover
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-head__mobile-menu d-block d-lg-none">
          <div
            onClick={() => setClickBurger(!clickBurger)}
            className="hamburger"
          >
            <span className={`hb-top ${clickBurger ? "shorter" : ""}`}></span>
            <span className="hb-middle"></span>
            <span className={`hb-bottom ${clickBurger ? "longer" : ""}`}></span>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${clickBurger ? "active" : ""}`}>
        <div className="mobile-menu__container container-fluid">
          <ul id="menu-main-1" className="mobile-menu__primary">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28">
              <a href="/you" className="menu__link">
                You
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27">
              <a href="/us" className="menu__link">
                Me
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26">
              <a href="/plan" className="menu__link">
                Plan
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-20 current_page_item menu__item menu-item-25">
              <a href="/invest" className="menu__link">
                Invest
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666">
              <a href="/pricing" className="menu__link">
                Pricing
              </a>
            </li>
            <li className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29">
              <a href="/contact" className="menu__link">
                Discover
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* main Invest */}
      <main>
        <nav className="plottspy navbar d-none d-md-block">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a className="nav-link" href="#section1"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section2"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section3"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section4"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section5"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section6"></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#section7"></a>
            </li>
          </ul>
        </nav>

        <div className="slides__colour">
          <div id="section1" className="section">
            <section
              id="section"
              className="hero-image panel"
              data-color="brown"
            >
              <div
                className="image-wrap--img"
                style={{
                  backgroundImage: "url('images/invest-1.jpg')",
                }}
                // style={{
                //   backgroundImage: "url('images/vobis_pic3.jpg')",
                // }}
              ></div>

              <div className="title-home">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <span>Invest</span>
                  <p  className="pp_sub">Fearlessly</p>
                </h1>
                <a href="#section2" className="smooth">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section2" className="section">
            <section
              className="panel centre-align-slide invest_sec2_color"
              data-color="blue"
              //    background-image: url('images/bg-question-centre.png');
              //   background-position: center;
              // style={{
              //   backgroundImage: "url('images/bg-vobis-left.png')",
              //   backgroundPosition: "center",
              // }}
            >
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                // style={{ fontSize: "2.8rem" }}
                className="invest_1st_p"
              >
                Investing involves deploying capital with the expectation of
                generating a return or profit over time. Here are our four
                fundamental principles of investing:
              </p>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section3" className="section">
            <section
              className="panel left-align-slide invest_sec3_color"
              data-color="brown"
              //   style="background-image: url('images/bg-leaf-right.png')"
              // style={{
              //   backgroundImage: "url('images/bg-leaf-right.png')",
              // }}
            >
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                className="invest_details_des"
              >
                <p className="invest_1st_p">Principle - Diversification</p>
                <h5 className="sub_invest_title">
                  Don't put all your eggs in one basket.
                </h5>
                <h5 className="desc_invest_sections">
                  Diversification involves spreading your investments across
                  different asset classes (such as stocks, bonds, real estate)
                  and within each asset class (different industries or sectors).
                  This helps to reduce the overall risk of your portfolio
                  because if one investment performs poorly, others may perform
                  well, balancing out potential losses. I coach you to
                  diversify.
                </h5>
              </div>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section4" className="section">
            <section
              className="panel right-align-slide invest_sec4_color"
              data-color="blue"
              //   style="background-image: url('images/bg-vobis-left.png')"
              // style={{
              //   backgroundImage: "url('images/bg-vobis-left.png')",
              // }}
            >
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                className="invest_details_des"
              >
                <p className="invest_1st_p">Principle - Risk and Return</p>
                <h5 className="sub_invest_title">
                  Higher potential returns usually come with higher levels of
                  risk.
                </h5>
                <h5 className="desc_invest_sections">
                  Investors need to carefully assess their risk tolerance and
                  time horizon. Investments with the potential for higher
                  returns typically involve higher levels of risk. Understanding
                  and managing this risk is crucial. Balancing the desire for
                  returns with an appropriate level of risk is key to
                  constructing a portfolio that aligns with an investor's
                  financial goals. I coach you to determine your risk levels.
                </h5>
              </div>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section5" className="section">
            <section
              className="panel left-align-slide invest_sec5_color"
              data-color="green"
              //   style="background-image: url('images/bg-leaf-right-low.png')"
              // style={{
              //   backgroundImage: "url('images/bg-leaf-right-low.png')",
              // }}
            >
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                className="invest_details_des"
              >
                <p className="invest_1st_p">Principle - Time Horizon</p>
                <h5 className="sub_invest_title">
                  The length of time an investor can hold an investment matters.
                </h5>
                <h5 className="desc_invest_sections">
                  Diverse investments have varying time horizons for optimal
                  returns. Typically, volatile assets like stocks are suitable
                  for long-term goals. Knowing when you'll need your money helps
                  determine the right investment mix. Longer time horizons
                  enable a more aggressive strategy, capitalizing on compounding
                  returns. I coach you to understand your time horizons.
                </h5>
              </div>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          <div id="section6" className="section">
            <section
              className="panel right-align-slide invest_sec6_color"
              data-color="grey"
              //   style="background-image: url('images/bg-vobis-left.png')"
              // style={{
              //   backgroundImage: "url('images/bg-vobis-left.png')",
              // }}
            >
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
                className="invest_details_des"
              >
                <p className="invest_1st_p">
                  Principle - Research and Informed Decision-Making
                </p>
                <h5 className="sub_invest_title">
                  Make investment decisions based on thorough research and
                  information.
                </h5>
                <h5 className="desc_invest_sections">
                  Before investing, conduct due diligence by researching
                  potential risks and rewards. Being an informed investor
                  involves staying updated on market trends, economic
                  indicators, and asset performance. Informed decision-making is
                  crucial for a resilient and successful investment strategy. I
                  coach you with informed research.
                </h5>
              </div>
              <div className="hero-arrow--wrap color-slides">
                <a href="#section2" className="smooth hero-arrow navi">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
                <a href="#footer" className="hero-arrow footer-link">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section>
          </div>

          {/* <div id="section7" className="section">
            <section className="panel" data-color="brown">
              <div className="carousel-wrap carousel-wrap--colour-slides">
                <div
                  id="tc"
                  className="testimonial carousel slide carousel-fade"
                  data-ride="true"
                  data-interval="8500"
                  data-pause="false"
                >
                  <h2>CLIENT CENTRIC APPROACH</h2>
                  <hr />
                  <div className="carousel-inner">
                    <div className="carousel-item">
                      <div className="carousel-item--text-wrap">
                        <h4>Risk Profile Assessment</h4>
                        <p></p>
                        <p>
                          We assess your risk profile, tolerance, and capacity
                          through a comprehensive questionnaire. The risk score
                          we assign is the result of research conducted by
                          leading academics in financial-risk decision making
                          and behavioural psychology. Our approach combines
                          elements of validated psychometrics and predictive
                          behavioural lotteries based on Prospect Theory,
                          leveraging the strengths of each to mitigate inherent
                          weaknesses.
                        </p>
                        <p></p>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="carousel-item--text-wrap">
                        <h4>Asset Allocation Strategy</h4>
                        <p></p>
                        <p>
                          Based on your unique risk profile, we meticulously
                          determine a suitable asset allocation. Recognizing
                          that no two clients are identical, we tailor this
                          allocation to each individual's distinct profile. Our
                          belief is that this personalized approach is essential
                          to accurately reflect the diversity of our clients.
                        </p>
                        <p></p>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="carousel-item--text-wrap">
                        <h4>Investment Selection Process</h4>
                        <p></p>
                        <p>
                          We take a proactive stance in recommending the best
                          funds tailored to each client's specific needs. Our
                          approach not only incorporates technical asset
                          allocation derived from their behavioural risk score
                          but also considers upcoming life needs. Life is
                          anything but vanilla, and our fund selection process
                          mirrors this diversity, ensuring a comprehensive and
                          personalized investment strategy.
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>

                  <ol className="carousel-indicators">
                    <li data-target="#tc" data-slide-to="0" className="button">
                      1
                    </li>
                    <li data-target="#tc" data-slide-to="1" className="button">
                      2
                    </li>
                    <li data-target="#tc" data-slide-to="2" className="button">
                      3
                    </li>
                   
                  </ol>
                </div>
              </div>
            </section>
          </div> */}

          <div id="section7" className="section">
            <section className="panel invest_sec7_color" data-color="brown">
              <div className="carousel-wrap carousel-wrap--colour-slides">
                <div className="testimonial">
                  <h2>CLIENT CENTRIC APPROACH</h2>
                  <hr />
                </div>
                <SliderInvest />
              </div>
            </section>
          </div>
        </div>
      </main>

      {/* footer */}
      <footer id="footer" className="snap">
        <img src="images/GanlazIconTwo-pre.svg" alt="ganlaz logo" />
        <h2>
          {/* Let<sup>,</sup>s talk. */}
          Embark on a
        </h2>
        <h2>new financial journey </h2>
        <h2>with a transformative discussion.</h2>
        <p className="book desk">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a
          <br />
          positive impact on your financial well-being.
        </p>
        <p className="book mob">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a positive impact on your financial well-being.
        </p>
        <div className="linkwrap">
          <a href="/contact">Contact us</a>
        </div>
        <hr />
        {/* <nav className="menu-footer-container">
          <ul id="menu-footer" className="footer">
            <li
              id="menu-item-127"
              className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-127"
            >
              <a
                href="https://ganlaxgroup.co.uk/legal/cookies/"
                className="menu__link"
              >
                Cookies
              </a>
            </li>
            <li
              id="menu-item-128"
              className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-128"
            >
              <a
                href="https://ganlaxgroup.co.uk/legal/terms/"
                className="menu__link"
              >
                Terms
              </a>
            </li>
            <li
              id="menu-item-129"
              className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-129"
            >
              <a
                href="https://ganlaxgroup.co.uk/legal/privacy/"
                className="menu__link"
              >
                Privacy
              </a>
            </li>
            <li
              id="menu-item-51"
              className="menu-item menu-item-type-custom menu-item-object-custom menu__item menu-item-51"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.plottcreative.co.uk/"
                className="menu__link"
              >
                Site by PLOTT
              </a>
            </li>
          </ul>
        </nav>{" "} */}
        <p class="small desk">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA <span>|</span>{" "}
          Official credential directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
        <p class="small mob">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA. Official credential
          directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
      </footer>
    </div>
  );
}

export default Invest;
