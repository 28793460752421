import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const pricingData1 = [
  {
    id: 1,
    title:
      "Initial meeting and completion of a full fact find, looking at current assets and liabilities, and incomes and expenditures",
  },
  {
    id: 2,
    title: "Discuss what your aims and plans are for the future    ",
  },
  {
    id: 3,
    title:
      "Discuss your Attitude to Investment Risk and your Capacity for Loss    ",
  },
  {
    id: 4,
    title:
      "Create a cashflow scenario report of your current situation – this will determine whether you have enough money to survive now and in retirement",
  },
  {
    id: 5,
    title:
      "Create a second cashflow scenario report - which involves putting in place various options to determine whether you have enough money to achieve your objectives",
  },
  {
    id: 6,
    title:
      "We will write you a suitability report giving you recommendations for you to digest and implement should you wish    ",
  },
];
const pricingData2 = [
  {
    id: 1,
    title: "Annual review meeting ",
  },
  {
    id: 2,
    title: "Revised cash flow Planning analysis and report where needed    ",
  },
  {
    id: 3,
    title: "Unrestricted access to us with 48 hour turnaround ",
  },
  {
    id: 4,
    title: "Discussion and education on all financial asset classes ",
  },
];

function Pricing() {
  const [clickBurger, setClickBurger] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <div
        className={`site-head container-fluid head-insights ${
          clickBurger ? "transparent-bg color-me" : ""
        }`}
      >
        <div className="site-head--logo">
          <a className="logo" href="/">
            <img src="images/GanlazTwo.png" alt="img" />
          </a>
        </div>
        <div className="site-head--nav d-none d-lg-block">
          <nav className="menu-main-container">
            <ul id="menu-main" className="nav primary">
              <li
                id="menu-item-28"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28"
              >
                <a href="/you" className="menu__link">
                  You
                </a>
              </li>
              <li
                id="menu-item-27"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27"
              >
                <a href="/us" className="menu__link">
                  Me
                </a>
              </li>
              <li
                id="menu-item-26"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26"
              >
                <a href="/plan" className="menu__link">
                  Plan
                </a>
              </li>
              <li
                id="menu-item-25"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25"
              >
                <a href="/invest" className="menu__link">
                  Invest
                </a>
              </li>
              <li
                id="menu-item-666"
                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-664 current_page_item current_page_parent menu__item menu-item-666"
              >
                <a href="/pricing" className="menu__link">
                  Pricing
                </a>
              </li>
              <li
                id="menu-item-29"
                className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29"
              >
                <a href="/contact" className="menu__link">
                  Discover
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-head__mobile-menu d-block d-lg-none">
          <div
            onClick={() => setClickBurger(!clickBurger)}
            className="hamburger"
          >
            <span className={`hb-top ${clickBurger ? "shorter" : ""}`}></span>
            <span className="hb-middle"></span>
            <span className={`hb-bottom ${clickBurger ? "longer" : ""}`}></span>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${clickBurger ? "active" : ""}`}>
        <div className="mobile-menu__container container-fluid">
          <ul id="menu-main-1" className="mobile-menu__primary">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-28">
              <a href="/you" className="menu__link">
                You
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27">
              <a href="/us" className="menu__link">
                Me
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26">
              <a href="/plan" className="menu__link">
                Plan
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25">
              <a href="/invest" className="menu__link">
                Invest
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-664 current_page_item current_page_parent menu__item menu-item-666">
              <a href="/pricing" className="menu__link">
                Pricing
              </a>
            </li>
            <li className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29">
              <a href="/contact" className="menu__link">
                Discover
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* amin Insights */}
      <main
        style={{
          backgroundColor: "#fff",
        }}
      >
        {/* <div className="insights-header">
          <div className="container-fluid insights-container">
            <div className="row align-items-end">
              <div className="col-lg-5 col-12">
                <h1 className="insights-header__heading">Pricing</h1>
              </div>
              <div className="col-lg-7 col-12">
                <p className="insights-header__subheading">
                  Grab a cuppa and read our latest pricing below to keep up to
                  date.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="section">
          <section className="hero-image">
            <div
              className="image-wrap--img"
              // background-image: url('images/vobis_pic3-1.jpg')
              style={{
                backgroundImage: "url('images/pp-1.jpg')",
              }}
            ></div>

            <div className="title-home">
              <h1
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <span>Pricing</span>
                <p  className="pp_sub">Simple and Transparent</p>
              </h1>
              <a href="#section2" className="smooth">
                <img src="images/arrow-down.svg" alt="arrow pointing down" />
              </a>
            </div>
          </section>
        </div>
        {/* <section
              id="section"
              className="hero-image panel"
              data-color="orange"
            >
              <div
                className="image-wrap--img"
                // background-image: url('images/vobis_pic3-1.jpg')
                style={{
                  backgroundImage: "url('images/Pricing.jpeg')",
                }}
              ></div>

              <div className="title-home">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Pricing
                </h1>
                <a href="#section2" className="smooth">
                  <img src="images/arrow-down.svg" alt="arrow pointing down" />
                </a>
              </div>
            </section> */}
        <div
          id="section2"
          style={{ paddingBottom: "20px" }}
          className="container-fluid insights-container pricing_class"
        >
          <div
            className="insights-post"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <div className="single-card" style={{ background: "#ccd5ae" }}>
              <div className="content-container">
                <div className="main-content">
                  <h2
                    className="single-card__heading new_haeding"
                    style={{ marginBottom: "26px" }}
                  >
                    Detailed Financial Plan with Investment Recommendations
                  </h2>
                  {pricingData1.map((pricing) => (
                    <div
                      key={pricing.id}
                      className="single-card__content"
                      style={{ marginBottom: "8px" }}
                    >
                      <div
                        className="p1"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <p style={{ fontSize: "30px" }}>•</p> {pricing.title}
                      </div>
                    </div>
                  ))}
                  <div className="bubble d-none d-lg-block">
                    {/* Cost{" "} */}
                    <span
                      className="span_price"
                      style={{ fontSize: "26px", fontWeight: "600" }}
                    >
                      £1000 – one off fixed fee
                    </span>
                  </div>
                  <div className="bubble d-block d-lg-none">
                    {/* Cost{" "} */}
                    <span
                      className="span_price"
                      style={{ fontSize: "26px", fontWeight: "600" }}
                    >
                      £1000 – one off fixed fee
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="insights-post"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <div className="single-card" style={{ background: "#d4a373" }}>
              <div className="content-container">
                <div className="main-content">
                  <h2
                    style={{ marginBottom: "26px" }}
                    className="single-card__heading new_haeding"
                  >
                    Ongoing Yearly Coaching
                  </h2>
                  {pricingData2.map((pricing) => (
                    <div
                      key={pricing.id}
                      className="single-card__content"
                      style={{ marginBottom: "8px" }}
                    >
                      <div
                        className="p1"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <p style={{ fontSize: "30px" }}>•</p> {pricing.title}
                      </div>
                    </div>
                  ))}
                  <div className="bubble d-none d-lg-block">
                    {/* Simple fixed fee{" "} */}
                    <span
                      className="span_price"
                      style={{ fontSize: "26px", fontWeight: "600" }}
                    >
                      £300 – per annum fixed fee
                    </span>
                  </div>
                  <div className="bubble d-block d-lg-none">
                    {/* Simple fixed fee{" "} */}
                    <span
                      className="span_price"
                      style={{ fontSize: "26px", fontWeight: "600" }}
                    >
                      £300 – per annum fixed fee
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* footer */}
      <footer
        id="footer"
        className="page_builder"
        style={{
          marginTop: "-4px",
          zIndex: "0",
        }}
      >
        <img src="images/GanlazIconTwo-pre.svg" alt="ganlaz logo" />
        <h2>Embark on a</h2>
        <h2>new financial journey </h2>
        <h2>with a transformative discussion.</h2>
        <p className="book desk">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a
          <br />
          positive impact on your financial well-being.
        </p>
        <p className="book mob">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a positive impact on your financial well-being.
        </p>
        <div className="linkwrap">
          <a href="/contact">Contact us</a>
        </div>
        <hr />

        <p class="small desk">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA <span>|</span>{" "}
          Official credential directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
        <p class="small mob">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA. Official credential
          directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
      </footer>
    </div>
  );
}

export default Pricing;
