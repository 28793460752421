import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import AnimatedText from "react-animated-text-content";
import AOS from "aos";
import "aos/dist/aos.css";

function You() {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  const [clickBurger, setClickBurger] = useState(false);

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.2,
        },
      });
    }
    if (!inView) {
      animation.start({ y: 40, opacity: 0 });
    }
  }, [inView, animation]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <div
        className={`site-head container-fluid ${clickBurger ? "transparent-bg color-me" : ""
          }`}
      >
        <div className="site-head--logo">
          <a className="logo" href="/">
            <img src="images/GanlazTwo.png" alt="img" />
          </a>
        </div>
        <div className="site-head--nav d-none d-lg-block">
          <nav className="menu-main-container">
            <ul id="menu-main" className="nav primary">
              <li
                id="menu-item-28"
                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-14 current_page_item menu__item menu-item-28"
              >
                <a href="/you" className="menu__link">
                  You
                </a>
              </li>
              <li
                id="menu-item-27"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27"
              >
                <a href="/us" className="menu__link">
                  Me
                </a>
              </li>
              <li
                id="menu-item-26"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26"
              >
                <a href="/plan" className="menu__link">
                  Plan
                </a>
              </li>
              <li
                id="menu-item-25"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25"
              >
                <a href="/invest" className="menu__link">
                  Invest
                </a>
              </li>
              <li
                id="menu-item-666"
                className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666"
              >
                <a href="/pricing" className="menu__link">
                  Pricing
                </a>
              </li>
              <li
                id="menu-item-29"
                className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29"
              >
                <a href="/contact" className="menu__link">
                  Discover
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-head__mobile-menu d-block d-lg-none">
          <div
            onClick={() => setClickBurger(!clickBurger)}
            className="hamburger"
          >
            <span className={`hb-top ${clickBurger ? "shorter" : ""}`}></span>
            <span className="hb-middle"></span>
            <span className={`hb-bottom ${clickBurger ? "longer" : ""}`}></span>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${clickBurger ? "active" : ""}`}>
        <div className="mobile-menu__container container-fluid">
          <ul id="menu-main-1" className="mobile-menu__primary">
            <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-14 current_page_item menu__item menu-item-28">
              <a href="/you" className="menu__link">
                You
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-27">
              <a href="/us" className="menu__link">
                Me
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-26">
              <a href="/plan" className="menu__link">
                Plan
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-25">
              <a href="/invest" className="menu__link">
                Invest
              </a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-666">
              <a href="/pricing" className="menu__link">
                Pricing
              </a>
            </li>
            <li className="bubble menu-item menu-item-type-post_type menu-item-object-page menu__item menu-item-29">
              <a href="/contact" className="menu__link">
                Discover
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* You */}

      <main>
        <div id="section1" className="section">
          <section
            id="section"
            className="hero-image "
            data-color="orange"
          >
            <div
              className="image-wrap--img"
              // background-image: url('images/vobis_pic3-1.jpg')
              style={{
                backgroundImage: "url('images/you-1.jpg')",
              }}
            ></div>

            <div className="title-home">
              <h1
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <span>You</span>
              </h1>
              <a href="#section2" className="smooth">
                <img src="images/arrow-down.svg" alt="arrow pointing down" />
              </a>
            </div>
          </section>
        </div>


        <div id="" className="section you_ganlax_section">
          <section
            className="you_ganlax"
            data-color="blue"
          //    background-image: url('images/bg-question-centre.png');
          //   background-position: center;
          // style={{
          //   backgroundImage: "url('images/bg-vobis-left.png')",
          //   backgroundPosition: "center",
          // }}
          >
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              // style={{ fontSize: "2.8rem" }}
              className="invest_1st_p"
            >
              Are you looking for financial guidance, perhaps financial coaching, or even some investment
              mentoring?
            </p>
            <div className="hero-arrow--wrap color-slides">
              <a href="#section2" className="smooth hero-arrow navi">
                <img src="images/arrow-down.svg" alt="arrow pointing down" />
              </a>
              <a href="#footer" className="hero-arrow footer-link">
                <img src="images/arrow-down.svg" alt="arrow pointing down" />
              </a>
            </div>
          </section>
        </div>

        <div id="section4">
          <div className="ganlax_feedback wrappeer">
            <h2 className="max-width box">
              Here are a few testimonials from my wonderful clients I have had the pleasure of
              coaching and mentoring.
            </h2>

            <div className="row align-items-top max-width box clients-row">
              <div
                className="col-12 col-lg-3 ganlax_feedback--image client-image"
              >
                <img
                  src="/images/Sophie.jpg"
                  className="img-responsive"
                  alt="img"
                />


              </div>

              <div
                className="col-12 col-lg-9 ganlax_feedback--profile client_profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-green">Sophie</h3>
                <p>
                  “I wish I had met Bhavesh 10 years ago! His extensive wealth of knowledge on investing and
                  how he breaks it down into bite sized chunks is amazing. <br />
                  What would have taken me 5-10 years to understand through self-teaching and research,
                  has taken only a matter of hours and days after our meetings.
                  Bhavesh has not just set up my investing portfolio, he has broadened my knowledge and
                  understand of the investing world, making it clearer and less scary. If you are interested in
                  starting your investing journey, you're in fantastic hands. Thank you Bhavesh”
                </p>
                <div className="buttons">
                  <a
                    className="client-readmore--link_1 client-readmore--link"
                    href="https://www.thefiercelash.co.uk/about-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span >Fierce Beauty - Beauty and Skincare - Business Owner</span>
                  </a>
                </div>
              </div>

              <hr className="color-green" />
            </div>
            <div className="row align-items-top max-width box clients-row">
              <div
                className="col-12 col-lg-3 ganlax_feedback--image client-image"
              >
                <img
                  src="/images/Enya.jpg"
                  className="img-responsive"
                  alt="img"
                />


              </div>

              <div
                className="col-12 col-lg-9 ganlax_feedback--profile client_profile"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <h3 className="color-green">Enya</h3>
                <p>
                  “I’m a self-employed Aesthetic Nurse and used Bhavesh’s financial coaching service as I
                  didn’t know where to start when it came to investing. I thought it was too complicated but
                  at the same time I wanted to ensure that I could put aside enough money for the future
                  whilst living life at the same time, Bhavesh really took this into account and explained
                  everything in layman’s terms. I now have a much better understanding of my own finances
                  and what my own goals are investment-wise.”
                </p>
                <div className="buttons">
                  <a
                    className="client-readmore--link_1 client-readmore--link"
                    href="https://www.thecosmedichouse.co.uk/about-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span >Cosmedic House – Medical Aesthetics - Business Owner</span>
                  </a>
                </div>
              </div>

              <hr className="color-green" />
            </div>
          </div>
        </div>


        <div id="section2" className="serc2 smc_4">
          <div className="text_with_title">
            <div className="text_with_title--row">
              <div className="max-width text">
                <h2
                  className="se3_text"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Have you settled into your career path but need financial
                  assistance?
                </h2>
              </div>
            </div>
            <div className="text_with_title--row">
              <div
                className="para max-width text green"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <p className="mancs">
                  Are you fearful about getting started, worried you will make
                  mistakes, and don't know where to begin? Struggling to
                  understand all the financial jargon or unsure about what
                  assets should be invested in for a prosperous future?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="section3">
          <div className="image_with_text ">
            <img
              src="images/you-22222.jpeg"
              alt="img"
            />
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay=""
              className="you_1st_h2"
              style={{
                width: "80%",
              }}
            >
              Well, these are all the types of questions we can help with. We
              assist everyone from early career starters, business owners to
              entrepreneurs and those mature in their careers. No matter how
              simple or complex your requirements are, we are here to guide and
              educate you toward the financial future you deserve.
            </h2>
          </div>
        </div>

        <div id="section4">
          <div className="section_links max-width box extra_padding">
            <div className="row">
              <div
                className="col-12 col-lg-6 match"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="section section_orange match">
                  <a href="/plan"></a>
                  <h3>Plan</h3>
                  <p>
                    Our wealth planning strategies are crafted to align with
                    your current lifestyle and evolve alongside your growing
                    wealth. We assist you in identifying your priorities and
                    create a flexible plan that can adapt seamlessly as you
                    navigate life's transitions.
                  </p>
                  <img src="images/arrow-right.svg" alt="img" />
                </div>
              </div>

              <div
                className="col-12 col-lg-6 match"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="section section_invest match">
                  <a href="/invest"></a>
                  <h3>Invest</h3>
                  <p>
                    We continuously adjust our investment strategies to
                    concentrate on global and innovative themes. By exclusively
                    investing in companies that embrace change and impact our
                    daily lives, we've delivered substantial returns for both
                    our investors and ourselves.
                  </p>
                  <img src="images/arrow-right.svg" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* footer */}
      <footer id="footer" class="page_builder">
        <img src="images/GanlazIconTwo-pre.svg" alt="ganlaz logo" />
        <h2>Embark on a</h2>
        <h2>new financial journey </h2>
        <h2>with a transformative discussion.</h2>
        <p className="book desk">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a
          <br />
          positive impact on your financial well-being.
        </p>
        <p className="book mob">
          Secure your complimentary discovery session with us, and let's explore
          how we can make a positive impact on your financial well-being.
        </p>
        <div className="linkwrap">
          <a href="/contact">Contact us</a>
        </div>
        <hr />

        <p class="small desk">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA <span>|</span>{" "}
          Official credential directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
        <p class="small mob">
          Bhavesh Patel, the director of Ganlax Investments Limited, is a
          Fellowship holder and regulated by AICPA & CIMA. Official credential
          directory can be found{" "}
          <a
            style={{
              color: "#fff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.aicpa-cima.com/directories/results/%3Fcountry=GBR&credential=FCMA&firstName=Bhavesh&fromForm=true&lastName=Patel&pageNumber=1&pageSize=12&searchType=1&zipCode=RM11"
          >
            here
          </a>
          .
          <br />
          Registered in England and Wales No. 10778074
        </p>
      </footer>
    </div>
  );
}

export default You;
