import Home from "./components/Home";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import You from "./components/You";
import Plan from "./components/Plan";
import OurPage from "./components/OurPage";
import Invest from "./components/Invest";
import Insights from "./components/Insights";
import Contact from "./components/Contact";
import Pricing from "./components/Pricing";

function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="you/*" element={<You />} />
          <Route path="plan/*" element={<Plan />} />
          <Route path="us/*" element={<OurPage />} />
          <Route path="invest/*" element={<Invest />} />
          {/* <Route path="insights/*" element={<Insights />} /> */}
          <Route path="pricing/*" element={<Pricing />} />
          <Route path="contact/*" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
